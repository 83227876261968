<template>
  <section class="modalWrapper">
    <div class="reservations-content-box" id="add_reserva">
      <div
        :class="{
          'reservaBar': type == 'Reserva',
          'precompraBar': type == 'Precompra',
        }"
        class="columns title-bar"
      >
        <div class="column"></div>
        <div class="column center-box">
          <span
            class="headerTitleReservations"
            v-if="!editReservation && !toList"
          >Agregar {{ type }}</span>
          <span class="headerTitleReservations" v-if="editReservation">Editar reserva</span>
          <span
            class="headerTitleReservations"
            v-if="toList && !isListEdit"
          >Agregar a lista de espera</span>
          <span class="headerTitleReservations" v-if="toList && isListEdit">Editar lista de espera</span>
          <span class="icons-box iconBoxHeader">
            <div class="box1">
              <i class="icon-mks black icon_person calendarIcon"></i>
              <span>{{ reservation.people }}</span>
            </div>
            <div class="box2">
              <i class="icon-mks black icon_calendar_voild calendarIcon"></i>
              <span v-if="reservation.date !== null">
                <div class="is-year">{{ reservation.date | moment("MMM") }}</div>
                <div class="the_day">{{ reservation.date | moment("DD") }}</div>
              </span>
              <span v-if="reservation.date === null">-</span>
            </div>
            <div class="box3">
              <i class="icon-mks black icon_time calendarIcon"></i>
              <span v-if="reservation.hour !== null ">
                {{
                hourFormat(parseInt(reservation.hour))
                }}
              </span>
              <span v-if="reservation.hour === null">-</span>
            </div>
          </span>
        </div>
        <div class="column close-later">
          <button @click="()=>{close()}" class="close-btn">
            <p class="closeButton">+</p>
          </button>
        </div>
      </div>
      <div :class="{toList:toList}" class="Contenedor">
        <div class="columns content-modal-mobile">
          <div class="column left-later">
            <div class="barra-left-top detailContainer">
              <div class="barra-left">
                <span class="title-box">Datos de la reserva</span>
                <Detail v-model="reservation"></Detail>
              </div>
            </div>
            <People :people="reservation.people" :adults="reservation.adult" :boys="reservation.boy" @setAdultsBoys="setAdultsBoys" @selected="(e) => setPeopleUpdate(e)"></People>
            <Date :time="reservation.date" @dateChange="(e)=>reservation.date=e"></Date>
            <Hour
              :editReservation="preselectedHour"
              :oldDate="preselectedDateForHour"
              :people="reservation.people"
              :time="reservation.date"
              @getAvailability="getAvailability"
              @hourChange="(e)=>{$set(reservation, 'Hour', e);$set(reservation, 'hour', e)}"
            ></Hour>
            <Zone
              :people="reservation.people"
              :timeHour="reservation.hour"
              :zoneSelected="initZone"
              @loading="(cargando)=>reservation.loadingZone=cargando"
              @seatSelected="setZone"
              v-if="!toList"
            ></Zone>
            <div :class="{hideSubzone: hideSubzone}">
              <Subzone
                :hour="reservation.hour"
                :people="reservation.people"
                :subZoneIdValue="initSubZone"
                :zoneId="reservation.selectZone"
                @show="(show)=>hideSubzone=!show"
                @subZoneSelected="
                (subzone) => (reservation.selectSubzone = subzone)
              "
                v-if="!toList"
              ></Subzone>
            </div>

            <Tables
              :dataNotPrecharged="NOTpreselectedTable"
              :hour="reservation.hour"
              :people="reservation.people"
              :selectedTablesClient="reservation.selectTable"
              :subZoneId="reservation.selectSubzone"
              :tablesValue="initTable"
              :zoneId="reservation.selectZone"
              @loading="(cargando)=>reservation.loadingTable=cargando"
              @tableSelected="
                (selectedTable) => (reservation.selectTable = selectedTable)
              "
              v-if="!toList"
            ></Tables>
            <AdjuntFile
              :editAdjunt="0"
              :initValue="infoUserInit.adjuntFile"
              @adjuntFile="updateAdjuntFile($event)"
              v-if="params.sendToClient && !toList"
            ></AdjuntFile>
            <Leyend></Leyend>
          </div>
          <div :class="{toList:toList}" class="divisor-reserva"></div>
          <div class="column rigth-later">
            <div class="barra-right-top">
              <div class="barra-right">
                <span class="title-box">Datos del usuario</span>
              </div>
            </div>
            <User
              :creating="true"
              :email="reservation.email"
              :indicativo="reservation.indicativo"
              :infoObject="formInformation"
              :initInfo="infoUserInit"
              :toList="toList"
              @bandera="reservationDashboard.bandera = $event"
              @formInfo="formInformation=$event"
              @getRequeriments="(e)=>{initNotes=e.notes;initTags=e.tags;}"
            ></User>
            <Payment @updateReservationInfoPay="setUpdatePayReservation($event)" v-if="type === 'Precompra' && !editReservation" :reservationInfo="reservation"></Payment>
            <Requirement
              :ammenitisValue="initamenities"
              :notesValue="initNotes"
              :queueType="toList"
              :tagsValue="initTags"
              :textBoxComment="initComment"
              :textBoxCommentRestaurant="initCommentRestaurant"
              @change="getRequirementValue"
            ></Requirement>
            <div class="articialSpace" v-if="params.activateGiftCard !== 1"></div>
            <GiftCard
              @changeGiftCardId="reservation.giftcardId = $event"
              @changePriceGiftCard="reservation.priceGiftCard = $event"
              v-if="params.activateGiftCard == 1 && !toList"
            ></GiftCard>
            <SelectComponent
              :field="'referrer'"
              :iconLeft="'origins'"
              :initValue="infoReserva ? infoReserva.referrer : null"
              :options="referrerVendor"
              :placeholder="'Origen de la reserva (Seleccione una opción)'"
              :typeData="'referrer'"
              @valueChange="(e)=>reservation={...reservation , ...e}"
              v-if="vendorOpParams.activeOriginsDashboard == 1 && !toList"
            ></SelectComponent>
            <SelectComponent
              :field="'experienceName'"
              :iconLeft="'people'"
              :initValue="infoReserva ? infoReserva.experienceName: null"
              :options="findExperience()"
              :placeholder="'Experiencias (Seleccione una opción)'"
              :typeData="'experience'"
              @valueChange="(e)=>reservation={...reservation , ...e}"
              v-if="false"
            ></SelectComponent>
            <div v-if="!toList && params.activateAdditionalQuestions">
              <SelectComponent
                :field="item._id"
                :iconLeft="item.name"
                :initValue="initAdditionals[item._id] ? initAdditionals[item._id].description:''"
                :key="'adittionals#' + key"
                :options="item.choice"
                :placeholder="item.name + ' (Seleccione una opción)'"
                :typeData="item.name"
                @valueChange="(e)=>additionalAnswrs={...e}"
                v-for="(item, key) in additionalQuestions"
              ></SelectComponent>
            </div>
            <div class="rigth-later-flex" v-if="vendorOpParams.isActiveOrigen == 1">
              <div class="rigth-later-width" v-if="!toList ">
                <SelectComponent
                  :field="'originDetails'"
                  :iconLeft="'company'"
                  :initValue="initOriginDetail"
                  :options="originVendor"
                  :placeholder="'Empresa (Seleccione una opción)'"
                  :typeData="'company'"
                  @addNewCompany="saveOriginDetail"
                  @valueChange="(e)=>reservation={...reservation , ...e}"
                ></SelectComponent>
              </div>
              <div class="rigth-later-width mr-15p" v-if="!toList">
                <SelectComponent
                  :field="'originPeople'"
                  :iconLeft="'people'"
                  :initValue="initOriginPerson"
                  :options="originVendorPeople"
                  :placeholder="'Persona (Seleccione una opción)'"
                  :typeData="'people'"
                  @addNewPerson="saveOriginPeople"
                  @valueChange="(e)=>reservation={...reservation , ...e}"
                ></SelectComponent>
              </div>
            </div>
            <Host
              :listHost="hostList"
              @createdAt="createdAtData = $event"
              @getHostId="(id)=>reservation.hostId=id"
              @hostData="pinHost = $event"
              @hostValue="hostValueData = $event"
              @showPinHost="hostPin = $event"
              @changeHiddenPasswordHost="(e) => hiddenPasswordHost = e"
              v-if="hostList.length > 0 && !toList"
            ></Host>
            <section class="inputPasswordSection" v-if="!toList && !hiddenPasswordHost">
              <div class="passwordContainer">
                <InputComponent
                  :borderNone="true"
                  :createdAtInfo="createdAtData"
                  :field="'pinHost'"
                  :hostValueInfo="hostValueData"
                  :iconLeft="'password'"
                  :iconRight="true"
                  :iconRightExact="'eye'"
                  :info="pinHost"
                  :placeholder="'Contraseña'"
                  :type="'text'"
                  @error="(e) => (errorPassword = e)"
                  @getHostID="(id)=>{
                    this.hiddenPasswordHost = true;
                    this.reservation.hostId=id
                  }"
                  v-if="hostPin "
                />
              </div>
              <label class="ErrorPasswordNotification" v-if="errorPassword">Contraseña incorrecta</label>
            </section>
            <div class="consumeParent" v-if="params.saveValueConsumed && editReservation">
              <div class="consumeValue">
                <div>
                  <p>$</p>
                </div>
                <input type="text" :placeholder="'Ingresar valor de consumo'" @input.prevent="cambioAlValuedConsume" :value="reservation.valueConsumed">
              </div>
              <div class="invoiceNumber ml-10">
                <div>
                  <img src="@/assets/icons/addReservation/factura.svg" alt="precompro">
                </div>
                <input type="text" :placeholder="'Número de factura'" v-model="reservation.invoiceNumber">
            </div>
            </div>
            <div class="notificationContainer">
              <Notification
                :phoneExist="reservation.phone ? true : false"
                :reservationInfo="reservation"
                @change="(e)=>reservation={...reservation,...e}"
                :isWaitingList="toList"
              ></Notification>
            </div>
          </div>
        </div>
        <div class="alert-bigbox contentExistent" v-if="infoExistente">
          <em class="icon-mks red icon_danger danger"></em>
          <div class="right-danger existent">
            <span class="this-is-error" v-html="existente"></span>
            <div class="butons-danger existent">
              <a @click="addTodo()" class="btn-dg" href="javascript:void(0)">
                Agregar
                <span></span>
              </a>
              <a @click="editTodo()" class="btn-dg" href="javascript:void(0)">
                Editar
                <span></span>
              </a>
            </div>
          </div>
        </div>
        <div class="alert-bigbox manualmente" v-if="error != null">
          <em class="icon-mks red icon_danger danger"></em>
          <span class="errorShow" v-html="error"></span>
          <a @click="error = null" class="btn-dg" href="#">
            <em class="fa fa-times"></em>
          </a>
        </div>
        <validateReservation
          :reservation="reservation"
          :typeMethod="editR ? 'edit': ''"
          @blockedSend="readyReservationShow = $event"
          v-if="validateReservationAdd"
        ></validateReservation>
        <div v-if="!infoExistente && !toList" style="position: relative;">
          <div class="button-box-action">
            <button
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              class="disabled"
              v-if="reservation.people == ''"
            >Selecciona cantidad de personas</button>
            <button
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              class="disabled"
              v-if="
              !reservation.loading &&
              !reservation.loadingTable &&
              !reservation.loadingZone &&
                reservation.people != '' &&
                !reservation.date
              "
            >Seleccione una fecha</button>
            <button
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              class="disabled"
              v-if="
              !reservation.loading &&
              !reservation.loadingTable&&
              !reservation.loadingZone&&
                reservation.people != '' &&
                reservation.date != null &&
                reservation.hour == null
              "
            >Selecciona una hora</button>
            <button
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              class="disabled"
              v-if="
                !reservation.loading &&
                !reservation.loadingTable &&
                !reservation.loadingZone &&
                reservation.people &&
                reservation.hour &&
                reservation.date &&
                reservation.displayName.length < 3
              "
            >Escribe el nombre del comensal</button>
            <button
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              class="disabled"
              v-if="
              !reservation.loading &&
              !reservation.loadingTable &&
              !reservation.loadingZone &&
                hostList.length > 0 &&
                reservation.people != '' &&
                reservation.hour != null &&
              reservation.displayName.length >= 3 && reservation.displayName &&
                reservation.hostId == null
              "
            >Selecciona un host</button>
            <button
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              @click="validateBeforeSubmit"
              v-if="
                reservation.people != '' &&
                reservation.date != null &&
                reservation.hour != null &&
                reservation.displayName != '' &&
                reservation.displayName != null &&
                (reservation.displayName.length >= 3 && reservation.displayName)  &&
                hostList.length === 0 &&
                !reservation.loading &&
                !reservation.loadingTable &&
                !reservation.loadingZone &&
                !reservation.errorMail &&
                !reservation.errorTel
              "
            >
              {{ (editReservation ? 'Actualizar ':'Agregar ') + type }}
              <span class="buttonIcon">+</span>
            </button>
            <button
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              @click="validateBeforeSubmit"
              v-if="
                reservation.people != '' &&
                reservation.date != null &&
                reservation.hour != null &&
                reservation.displayName != '' &&
                reservation.displayName != null &&
                reservation.displayName.length >= 3 && reservation.displayName &&
                !reservation.loading &&
                !reservation.loadingTable &&
                !reservation.loadingZone &&
                !reservation.errorMail &&
                !reservation.errorTel &&
                reservation.hostId != null &&
                hostList.length > 0
              "
            >
              {{ (editReservation ? 'Actualizar ':'Agregar ') + type }}
              <span class="buttonIcon">+</span>
            </button>
            <button
              v-else-if="reservation.errorMail"
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              class="disabled"
            >
              Valide la información del cliente
            </button>
            <span
              :class="{
                'btn-reservas': type == 'Reserva',
                'btn-precompras': type == 'Precompra',
              }"
              class="disabled"
              href="javascript:void(0)"
              v-if="reservation.loading || reservation.loadingTable || reservation.loadingZone"
            >
              <i class="fa fa-spinner fa-spin"></i> Cargando...
            </span>
          </div>
        </div>
        <div v-else-if="toList" style="position: relative;">
          <div class="button-box-action">
            <button
              :class="{disabled: reservation.hour ? false : true}"
              class="btn-reservas"
              :disabled="saving ? true : false"
              @click="saveItemList(false)"
              v-if="isListEdit"
            >{{reservation.hour ? 'Editar de la lista de espera' : 'Selecciona hora y fecha'}}</button>
            <button
              :disabled="saving ? true : false"
              @click="saveItemList(true)"
              class="btn-reservas"
              v-if="isListEdit"
            >
              <i class="icon_dashboard_metro icon-mks white"></i>Sentar reserva
            </button>
            <button
              :class="{disabled:reservation.hour ? false : true}"
              :disabled="(saving ? true : false)"
              @click="saveItemList(false)"
              class="btn-reservas"
              v-if="!isListEdit"
            >{{reservation.hour ? 'Agregar a la lista de espera' : 'Selecciona hora y fecha'}}</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Payment from '@/components/_shared/FormReservation/Payment';
import People from '@/components/_shared/FormReservation/People';
import Date from '@/components/_shared/FormReservation/Date';
import Hour from '@/components/_shared/FormReservation/Hour';
import Zone from '@/components/_shared/FormReservation/Zone';
import Subzone from '@/components/_shared/FormReservation/Subzone';
import Tables from '@/components/_shared/FormReservation/Table';
import User from '@/components/_shared/FormReservation/User';
import AdjuntFile from '@/components/_shared/FormReservation/AdjuntFile';
import Requirement from '@/components/_shared/FormReservation/Requirement';
import validateReservation from '@/components/_shared/validateReservation';
import moment from 'moment';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import reservationTypes from '@/store/types/reservation';
import LocalStorage from 'store';
import Host from './FormReservation/Host.vue';
import Detail from './FormReservation/Detail.vue';
import Leyend from './FormReservation/Leyend.vue';
import GiftCard from './FormReservation/GiftCard.vue';
import SelectComponent from '../Extends/Select.vue';
import Notification from './FormReservation/Notification.vue';
import InputComponent from './../Extends/Input.vue';

export default {
  name: 'AddReservation',
  props: ['type', 'editReservation', 'infoReserva', 'editR', 'itemList', 'toList'],
  components: {
    People,
    Date,
    Hour,
    Zone,
    Subzone,
    Tables,
    User,
    AdjuntFile,
    Requirement,
    validateReservation,
    Host,
    Detail,
    Leyend,
    GiftCard,
    SelectComponent,
    Notification,
    InputComponent,
    Payment
  },
  data () {
    return {
      hideSubzone: true,
      initAdditionals: {},
      formInformation: {},
      window: {
        width: 0,
        height: 0
      },
      additionalAnswrs: {},
      errorPassword: false,
      hostPin: false,
      pinHost: null,
      hostValueData: null,
      createdAtData: null,
      validateReservationAdd: true,
      hourAvailability: [],
      edit: false,
      infoExistente: false,
      existente: null,
      hostList: [],
      typeReservationList: [],
      createdAt: null,
      notificationEmail: true,
      notificationSms: false,
      notificationWhatsApp: false,
      error: null,
      selectedAdditional: {},
      oldDate: null,
      referrerVendor: [],
      readyReservationShow: true,
      giftcardId: null,
      priceGiftCard: 0,
      initZone: [],
      reservation: {
        valueConsumed: null,
        invoiceNumber: null,
        notificationWhatsApp: false,
        notificationSms: false,
        notificationEmail: true,
        people: 1,
        adult: 1,
        boy: 0,
        date: null,
        address: null,
        hour: null,
        selectZone: [],
        selectSubzone: [],
        selectTable: [],
        displayName: '',
        typeReservation: 'Normal',
        typeNotification: 'email',
        email: null,
        identityDocument: '',
        phone: null,
        indicativo: '57',
        pais: 'Colombia ( +57 )',
        paises: [],
        subscribed: false,
        bandera: 'https://api.precompro.com/banderas/co.png',
        amenities: [],
        comments: null,
        loading: true,
        errorMail: null,
        errorTel: null,
        hostId: null,
        referrer: null,
        originDetails: null,
        originPeople: null,
        newOriginP: null,
        newOriginD: null,
        experienceName: null,
        celebrationId: null,
        adjuntFile: null,
        tags: [],
        notes: '',
        sendToClient: 1,
        birthday: null,
        balancePaid: 0,
        typeDocument: 'CC',
        id: null,
        loadingTable: false,
        loadingZone: false,
        paymentType: 'normal',
        amount: null,
        isWarranty: 0,
        priceWarranty: 0,
        priceWarrantyBoy: 0,
        priceTypeWarranty: 'person'
      },
      reservationClear: {
        notificationWhatsApp: false,
        notificationSms: false,
        notificationEmail: true,
        people: 2,
        adult: 2,
        boy: 0,
        date: null,
        address: null,
        hour: null,
        selectZone: [],
        selectSubzone: [],
        selectTable: [],
        displayName: '',
        typeReservation: 'Normal',
        typeNotification: 'email',
        email: null,
        identityDocument: '',
        phone: null,
        indicativo: '57',
        pais: 'Colombia ( +57 )',
        paises: [],
        subscribed: false,
        bandera: 'https://api.precompro.com/banderas/co.png',
        amenities: [],
        comments: null,
        loading: true,
        errorMail: null,
        errorTel: null,
        hostId: null,
        referrer: null,
        newOriginP: null,
        newOriginD: null,
        experienceName: null,
        celebrationId: null,
        adjuntFile: null,
        tags: [],
        notes: '',
        sendToClient: 1,
        birthday: null,
        balancePaid: 0,
        typeDocument: 'CC',
        id: null,
        loadingTable: false,
        loadingZone: false,
        paymentType: 'normal',
        amount: null,
        isWarranty: 0,
        priceWarranty: 0,
        priceWarrantyBoy: 0,
        priceTypeWarranty: 'person'
      },
      initSubZone: [],
      initTable: [],
      initamenities: [],
      initTags: [],
      initNotes: '',
      initComment: null,
      initCommentRestaurant: null,
      isLegalPerson: false,
      legalPersonName: '',
      hourMapped: false,
      saving: false,
      openEditNext: false,
      fromMapSetHour: false,
      initTypeDocument: 'CC',
      initOriginDetail: '',
      initOriginPerson: '',
      hiddenPasswordHost: false
    };
  },
  created () {
    this.$store.commit(reservationTypes.mutations.setFrontModal, true);
  },
  async beforeMount () {
    this.reservation.loading = true;
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    try {
      const bodyEl = document.body;
      // eslint-disable-next-line
      classie.remove(bodyEl, "show-right-bar");
      if (this.fromTimeLine) {
        // eslint-disable-next-line no-undef
        classie.remove(bodyEl, 'cronology');
      }
    } catch (err) {
      console.error(err);
    }
    if (this.params.activeOriginsDashboard) {
      this.$store
        .dispatch({
          type: 'reservation:getReferersVendors',
          data: {
            vendorId: localStorage.getItem('_id')
          }
        })
        .then((data) => {
          data.data.forEach((element) => {
            this.referrerVendor.push(element.referrer);
          });
        })
        .catch((fallo) => {
          this.error = fallo;
          this.reservation.loading = false;
          // this.reservation = this.reservationClear;
        });
    }
    // this.getTypeReservationList() fase 2 gaira
    // buscar origenes solo para determinados vendorsId
    if (this.params.isActiveOrigen === 1) {
      this.$store
        .dispatch({
          type: 'reservation:getOriginVendor',
          data: {
            vendorId: this.vendorId
          }
        })
        .catch((fallo) => {
          this.reservation.originDetails = null;
          this.error = fallo;
          this.reservation.loading = false;
          // this.reservation = this.reservationClear;
        });
      this.$store
        .dispatch({
          type: 'reservation:getOriginVendorPeople',
          data: {
            vendorId: this.vendorId
          }
        })
        .catch((fallo) => {
          this.error = fallo;
          this.reservation.originPeople = null;
          this.reservation.loading = false;
          // this.reservation = this.reservationClear;
        });
    }
    // buscar preguntas adicionales solo para determinados vendorsId
    if (this.params.activateAdditionalQuestions === 1) {
      this.$store
        .dispatch({
          type: 'reservation:getAdditionalQuestions',
          data: {
            vendorId: this.idVendor
          }
        })
        .catch(() => {
          this.reservation.originPeople = null;
          this.reservation.loading = false;
          // this.reservation = this.reservationClear;
        });
    }
    // in table
    if (this.fromMap) {
      this.edit = true;
      this.reservationDashboard.date = this.$moment(this.selectedDate).format('YYYY-MM-DD');
      if (this.sectionSelected !== 0) {
        this.initZone.push(this.sectionSelected);
      }
      if (this.tableSelectedId !== 0) {
        this.initTable = [this.tableSelectedId];
      }
      if (this.hourfromMap) {
        this.reservation.date = this.$moment(this.selectedDate).format('YYYY-MM-DD');
        this.reservation.hour = parseInt(this.$moment(this.hourfromMap).format('x'));
        this.hourMapped = false;
      } else {
        this.reservation.date = this.$moment(this.selectedDate).format('YYYY-MM-DD');
        this.hourMapped = true;
      }
    }
    if ((this.infoReservation && this.editR)) {
      const infoReser = JSON.parse(JSON.stringify(this.infoReservation));
      if (this.infoReservation) {
        this.reservation.people = infoReser.people;
        this.reservation.adult = infoReser.adult === 0 && infoReser.boy === 0 && infoReser.people > 0 ? infoReser.people : infoReser.adult;
        this.reservation.boy = infoReser.boy;
        this.reservation.referrer = infoReser.referrer;
        this.reservation.displayName = infoReser.displayName;
        this.reservation.phone = infoReser.phone;
        this.reservation.email = infoReser.email;
        this.reservation.peopleId = infoReser.peopleId;
        const opciones = { maximumFractionDigits: 0, currency: 'COP' };
        const formatoNumero = new Intl.NumberFormat('es-CO', opciones);
        this.reservation.valueConsumed = formatoNumero.format(infoReser.valueConsumed);
        this.reservation.invoiceNumber = infoReser.invoiceNumber ? infoReser.invoiceNumber : null;
        this.initOriginDetail = infoReser.originDetail ? infoReser.originDetail : '';
        this.initOriginPerson = infoReser.originPeople ? infoReser.originPeople : '';
        this.reservation.typeNotification = infoReser.typeNotification;
        this.reservation.notificationType = infoReser.typeNotification;
        this.reservation.date = infoReser.fecha;
        this.reservation.isRequest = infoReser.isRequest || 0;
        this.reservation.balancePaid = infoReser.balancePaid || 0;
        this.reservation.isWarranty = infoReser.isWarranty || 0;
        this.reservation.priceTypeWarranty = infoReser.priceTypeWarranty || 'person';
        this.reservation.priceWarranty = infoReser.priceWarranty || 0;
        this.reservation.priceWarrantyBoy = infoReser.priceWarrantyBoy || 0;
        this.reservation.hour = parseInt(infoReser.date);
        let helper = [];
        let helperSub = [];
        const helperTab = infoReser.tableId.split(',');
        if (infoReser.subSectionId) {
          helperSub = infoReser.subSectionId.split(',');
          helperSub = helperSub.map(value => parseInt(value.trim()));
        }
        if (infoReser.selectZone) {
          infoReser.selectZone.forEach(id => {
            if (typeof id === 'string') {
              helper.push(parseInt(id.trim()));
            } else {
              helper.zoneId.push(id);
            }
          });
        } else {
          helper = infoReser.sectionId.split(',');
          helper = helper.map(id => {
            return parseInt(id.trim());
          });
        }
        this.initZone = [...helper];
        this.initSubZone = helperSub;
        this.initTable = helperTab.map(value => parseInt(value.trim()));
        this.initamenities = infoReser.services;
        this.initComment = infoReser.comments;
        this.initCommentRestaurant = infoReser.commentRestaurant;
        this.reservation.selectTable = this.initTable;
      }
      this.reservation._id = infoReser._id;
      this.reservation.id = infoReser._id;
      this.reservation.reservationId = infoReser.id;
      if (this.params.isActiveOrigen === 1) { // buscar origenes solo para determinados vendorsId
        this.$store.dispatch({ type: 'reservation:getOriginVendor', data: { vendorId: this.vendorId } })
          .then(data => {
            data.data.forEach(element => {
              const origin = element.item.find(e => e.name === this.originVendorS);
              if (origin !== undefined) {
                this.reservation.originDetails = origin;
              }
            });
          })
          .catch(fallo => {
            this.reservation.originDetails = null;
            this.error = fallo;
            this.reservation.loading = false;
            // this.reservation = this.reservationClear;
          });
        this.$store.dispatch({ type: 'reservation:getOriginVendorPeople', data: { vendorId: this.vendorId } })
          .then(data => {
            this.reservation.originPeople = data.data.find(e => e.name === this.originVendorPeopleS);
          })
          .catch(fallo => {
            this.error = fallo;
            this.reservation.originPeople = null;
            this.reservation.loading = false;
            // this.reservation = this.reservationClear;
          });
      }
      if (infoReser.peopleId != null) {
        this.$store.dispatch({ type: 'people:getOneUser', data: { id: this.infoReservation.peopleId } }).then(({ data }) => {
          if (data.tags !== undefined) {
            this.reservation.tags = data.tags;
            this.initTags = data.tags;
          }
          if (data.notes !== undefined) {
            this.reservation.notes = data.notes;
            this.initNotes = data.notes;
          }
          this.reservation.isVip = data.isVip;
          if (data.isRecommended) {
            this.reservation.isRecommended = data.isRecommended;
          }
          if (data.isHabitual) {
            this.reservation.isHabitual = data.isHabitual;
          }
          this.reservation.isYate = data.isYate;
          this.initTypeDocument = data.typeDocument;
        });
      }
      this.$store.dispatch({ type: 'reservation:getAnswers', data: { reservationId: infoReser.id } })
        .then(response => {
          if (response) {
            this.initAdditionals = (Object.keys(response.answers).length > 0) ? response.answers : {};
          }
        })
        .catch(() => {
          this.reservation.originPeople = null;
          this.reservation.loading = false;
          // this.reservation = this.reservationClear;
        });
    }
    if (this.itemList && this.toList) {
      this.reservation.typeNotification = this.itemList.typeNotification;
      this.reservation.notificationType = this.itemList.typeNotification;
    }
    await this.getDataHost();
    this.reservation.loading = false;
  },
  mounted () {
    if (this.itemList && this.toList) {
      if (this.itemList.id) {
        const helper = this.$moment(this.itemList.date).format('YYYY-MM-DD');
        this.reservation.date = helper;
        this.reservation.hour = parseInt(this.$moment(this.itemList.date).format('x'));
        this.reservation.email = this.itemList.email ? this.itemList.email : '';
        this.reservation.displayName = this.itemList.name ? this.itemList.name : '';
        this.reservation.name = this.itemList.name ? this.itemList.name : '';
        this.reservation.phone = this.itemList.phone ? this.itemList.phone : '';
        this.reservation.people = this.itemList.people ? this.itemList.people : 2;
        this.reservation.adult = (this.itemList.adult && this.itemList.adult === 0) &&
          (this.itemList.boy && this.itemList.boy === 0) &&
          (this.itemList.people && this.itemList.people > 0) ? this.itemList.people
          : (this.itemList.adult ? this.itemList.adult : 2);
        this.reservation.boy = this.itemList.boy ? this.itemList.boy : 0;
        this.initTimeQueue = `${Number(this.itemList.waitingTime) / 60000} minutos`;
        this.reservation.id = this.itemList.id;
        this.initComment = this.itemList.comment;
        this.initCommentRestaurant = this.itemList.commentRestaurant;
        this.reservation.notes = this.itemList.notes ? this.itemList.notes : '';
        this.initNotes = this.itemList.notes ? this.itemList.notes : '';
        this.reservation.tags = this.itemList.tags ? this.itemList.tags : [];
        this.initTags = this.itemList.tags ? this.itemList.tags : [];
        this.reservation.isVip = this.itemList.isVip;
        this.reservation.recommended = this.itemList.isRecommended;
        this.reservation.habitual = this.itemList.isHabitual;
        this.reservation.isYate = this.itemList.isYate;
        this.reservation.peopleId = this.itemList.peopleId;
        this.reservation.typeNotification = this.itemList.typeNotification;
        this.reservation.notificationType = this.itemList.typeNotification;
      }
    }
    try {
      if (this.fromTimeLine) {
        var bodyEl = document.body;
        // eslint-disable-next-line no-undef
        classie.remove(bodyEl, 'cronology');
      }
    } catch (err) {
      console.error(err);
    }
  },
  destroyed () {
    this.$store.commit(reservationTypes.mutations.setEditReservation, false);
    this.$store.commit(reservationTypes.mutations.setFromMap, false);
    this.$store.commit(reservationTypes.mutations.setFrontModal, false);
    this.$store.commit(
      reservationTypes.mutations.setReservationHourTimeLine,
      ''
    );
    window.removeEventListener('resize', this.handleResize);
    if (this.openEditNext) {
      this.setEditReservation(this.infoExistente);
    }
    if (this.fromTimeLine) {
      var bodyEl = document.body;
      // eslint-disable-next-line no-undef
      classie.add(bodyEl, 'cronology');
    }
  },
  computed: {
    isListEdit () {
      if (this.itemList) {
        if (this.itemList.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    infoUserInit () {
      if (this.getEditReservation) {
        return { ...this.infoReservation, typeDocument: this.initTypeDocument };
      } else if (this.toList) {
        return { ...this.itemList, displayName: this.itemList.name };
      } else {
        return {};
      }
    },
    preselectedHour () {
      if (this.editR || this.hourMapped) {
        return true;
      } else if (this.itemList) {
        if (this.itemList.id) {
          return true;
        }
        return false;
      } else if (this.hourfromMap) {
        return true;
      } else {
        return false;
      }
    },
    preselectedDateForHour () {
      if (this.editR) {
        return this.infoReservation.date;
      } else if (this.itemList) {
        return this.itemList.date;
      } else if (this.hourfromMap) {
        return parseInt(this.$moment(this.hourfromMap).format('x'));
      } else {
        return this.oldDate;
      }
    },
    NOTpreselectedTable () {
      if (this.editR || this.fromMap) {
        return false;
      } else {
        return true;
      }
    },
    ...mapGetters({
      additionalQuestions: [reservationTypes.getters.additionalQuestions],
      companionsData: reservationTypes.getters.companionsData,
      reservationDashboard: reservationTypes.getters.reservationDashboard,
      hourfromMap: [reservationTypes.getters.getReservationHourTimeLine],
      fromTimeLine: [reservationTypes.getters.fromTimeLine]
    }),
    isOpen: {
      get () {
        return this.value;
      },
      set (value) {
        this.setCloseReservation(value);
      }
    }
  },
  methods: {
    setUpdatePayReservation (e) {
      this.reservation.paymentType = e.paymentType;
      this.reservation.amount = e.amount;
    },
    cambioAlValuedConsume (e) {
      let number = e.target.value;
      if (/[^0-9.]/g.test(number)) {
        number = number.slice(0, -1);
      }
      const numberWithoutFormat = number.replace(/[.,]/g, '');
      const opciones = { maximumFractionDigits: 0, currency: 'COP' };
      const formatoNumero = new Intl.NumberFormat('es-CO', opciones);
      this.reservation.valueConsumed = formatoNumero.format(numberWithoutFormat);
      e.target.value = this.reservation.valueConsumed;

      // this.reservation.valueConsumed = formatoNumero.format(e.target.value);
    },
    saveItemList (sendToReserves) {
      this.reservation.referrer = 'dashboard';
      this.reservation.vendorId = this.vendorId;
      this.reservation.name = this.reservation.displayName;
      this.reservation.notificationType = 'email';
      this.reservation.userTags = this.reservation.tags;
      this.reservation.userNotes = this.reservation.notes;
      this.reservation.userNotes = this.reservation.userNotes ? this.reservation.userNotes : '';
      if (this.reservation.notificationSms) {
        this.reservation.notificationType = 'sms';
        this.reservation.typeNotification = 'sms';
      }
      if (this.reservation.notificationEmail) {
        this.reservation.notificationType = 'email';
        this.reservation.typeNotification = 'email';
      }
      if (this.reservation.notificationWhatsApp) {
        this.reservation.notificationType = 'whatsapp';
        this.reservation.typeNotification = 'whatsapp';
      }
      if (this.reservation.hour != null) {
        this.saving = true;
        this.dateValidation = false;
        this.reservation.date = this.reservation.hour;
        if (this.reservation.id === null) {
          this.$store
            .dispatch({
              type: 'waitingList:addWaitingListItem',
              data: this.reservation
            })
            .then(response => {
              if (response.code === 200) {
                this.$emit('close-modal-itemList', sendToReserves);
                var bodyEl = document.body;
                // eslint-disable-next-line
                classie.remove(bodyEl, 'show-right-bar')
                // eslint-disable-next-line
                classie.add(bodyEl, 'show-right-bar')
              }
            });
        } else {
          this.$store
            .dispatch({
              type: 'waitingList:updateWaitingListItem',
              data: this.reservation
            })
            .then(response => {
              if (response.code === 200) {
                this.$emit('close-modal-itemList', sendToReserves);
                this.saving = false;
                var bodyEl = document.body;
                // eslint-disable-next-line
                classie.remove(bodyEl, 'show-right-bar')
                // eslint-disable-next-line
                classie.add(bodyEl, 'show-right-bar')
              }
            });
        }
      }
    },
    handleResize () {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    hourFormat (date) {
      return this.$moment(date).format('hh:mm a');
    },
    setZone (e) {
      this.reservation.selectZone = e;
    },
    getRequirementValue (e) {
      this.reservation.amenities = e.amenities;
      this.reservation.comments = e.comments;
      this.reservation.commentRestaurant = e.commentRestaurant;
      this.reservation.tags = e.tags;
      this.reservation.notes = e.notes;
      if (this.toList && e.comments) {
        this.reservation.comment = e.comments;
      }
    },
    getAvailability (availability) {
      if (this.fromMap && !this.fromMapSetHour && !this.hourfromMap) {
        const now = parseInt(this.$moment().format('x'));
        for (let index = 0; index < availability.length; index++) {
          if (parseInt(availability[index].date) > now) {
            this.oldDate = parseInt(availability[index].date);
            break;
          }
        }

        this.fromMapSetHour = true;
      }
      this.hourAvailability = availability;
    },
    companionActions () {
      this.$emit('openCompanion', true);
      this.$emit('newCompanions', true);
    },
    setTags (e) {
      this.reservationDashboard.tags = e;
    },
    setNotes (e) {
      this.reservationDashboard.notes = e;
    },
    isOpenset () {
      if (this.toList) {
        this.$emit('close-modal-itemList', false);
        var bodyEl = document.body;
        // eslint-disable-next-line
        classie.remove(bodyEl, 'show-right-bar')
        // eslint-disable-next-line
        classie.add(bodyEl, 'show-right-bar')
      } else {
        const bodyEl = document.body;
        // eslint-disable-next-line
        classie.remove(bodyEl, "show-right-bar");
        // eslint-disable-next-line
        classie.add(bodyEl, "show-right-bar");
        if (this.fromTimeLine) {
          const bodyEl = document.body;
          // eslint-disable-next-line no-undef
          classie.add(bodyEl, 'cronology');
          // eslint-disable-next-line
          classie.remove(bodyEl, 'show-right-bar')
        }
        this.$emit('companionClose', false);
        this.isOpen = false;
      }
    },
    setTypeReservation (type) {
      if (this.reservationDashboard.typeReservation === type) {
        this.reservationDashboard.typeReservation = 'Normal';
      } else {
        this.reservationDashboard.typeReservation = type;
      }
    },
    isSelectHost (host) {
      if (host === this.createdAt) {
        return true;
      } else {
        return false;
      }
    },
    isSelectType (type) {
      if (type === this.reservationDashboard.typeReservation) {
        return true;
      } else {
        return false;
      }
    },
    addTodo () {
      this.infoExistente = false;
      this.existente = null;
      if (this.type === 'Precompra') {
        this.sendReservationLink();
      } else {
        this.sendReservation();
      }
    },
    editTodo () {
      this.close();
      this.openEditNext = true;
    },
    validateBeforeSubmit () {
      this.error = null;
      this.$validator.validateAll()
        .then((result) => {
          if (!result) return;
          if (
            this.reservation.people !== '' &&
            this.reservation.date !== '' &&
            this.reservation.hour !== '' &&
            this.reservation.displayName !== '' &&
            this.reservation.displayName.length >= 2
          ) {
            if (!this.editR) {
              this.validateReservation();
            } else {
              this.sendReservation();
            }
          }
        });
    },
    validateReservation () {
      const { displayName, phone, email, hour } = this.reservation;
      this.reservation.loading = true;
      const exist = this.hourAvailability.find((h) => h.date === hour);
      if (!exist) {
        this.reservation.loading = false;
        this.error = 'Debe seleccionar una hora';
        return;
      }
      if (parseInt(exist.date) < moment().valueOf()) {
        this.reservation.loading = false;
        this.error = 'Debe seleccionar una hora';
        return;
      }
      const vendorId = this.vendorId;
      this.$store
        .dispatch({
          type: 'reservation:validateReservation',
          data: {
            displayName: displayName,
            phone: phone,
            date: hour,
            email: email,
            vendorId: vendorId
          }
        })
        .then(({ data }) => {
          if (data.code === 200 && !data.existe) {
            this.validateReservationAdd = false;
            setTimeout(() => {
              this.validateReservationAdd = true;
            }, 10);
            setTimeout(() => {
              if (this.type === 'Precompra') {
                this.sendReservationLink();
              } else {
                this.sendReservation();
              }
            }, 500);
          } else {
            this.reservation.loading = false;
            this.infoExistente = data.reservation[0];
            this.existente =
              '<i class="fa fa-exclamation-circle"></i><span class="f14 fw-600 contentExistent">Este cliente ya tiene una reserva para la fecha seleccionada.</span>';
          }
        })
        .catch((fallo) => {
          this.error = fallo;
          this.reservation.loading = false;
          // this.reservation = this.reservationClear;
        });
    },
    sendReservationLink () {
      LocalStorage.set('sendReservation', 1);
      let {
        amenities,
        comments,
        indicativo,
        bandera,
        phone,
        email,
        identityDocument,
        adjuntFile,
        hostId,
        people,
        adult,
        boy,
        hour,
        selectZone,
        selectTable,
        displayName,
        experienceName,
        temperature,
        celebrationId,
        sendToClient,
        subscribed,
        referrer,
        tags,
        notes,
        isVip,
        isRecommended,
        isHabitual,
        isYate,
        birthday,
        balancePaid,
        typeDocument,
        peopleId,
        amount,
        paymentType,
        invoiceNumber
      } = this.reservation;
      const {
        notificationEmail
      } = this;
      const userId = this.user.id;
      this.reservation.loading = true;
      const vendorId = this.vendorId;
      selectZone = this.purgeZone(selectZone, selectTable);
      balancePaid = parseInt(people) * this.params.purchaseCost;
      if (this.reservation.notificationSms) {
        this.reservation.notificationType = 'sms';
        this.reservation.typeNotification = 'sms';
      }
      if (this.reservation.notificationEmail) {
        this.reservation.notificationType = 'email';
        this.reservation.typeNotification = 'email';
      }
      if (this.reservation.notificationWhatsApp) {
        this.reservation.notificationType = 'whatsapp';
        this.reservation.typeNotification = 'whatsapp';
      }
      if (this.reservation.indicative) {
        indicativo = this.reservation.indicative;
      }
      const dataReservation = {
        ...this.reservation,
        typeDocument: typeDocument || null,
        displayName,
        amenities,
        phone: phone || null,
        indicativo,
        bandera,
        email: email || null,
        identityDocument: identityDocument || null,
        adjuntFile,
        sendToClient,
        celebrationId,
        comments,
        createdAt: hostId,
        people,
        adult,
        boy,
        date: hour,
        seatingSectionId: selectZone,
        seatingTableId: selectTable,
        vendorId,
        userId,
        referrer: (referrer != null) ? referrer : 'dashboard',
        isConfirmed: this.isConfirmadas,
        notificationEmail,
        temperature,
        experienceName,
        additionalQuestions: this.selectedAdditional,
        subscribed,
        giftcardId: this.giftcardId,
        giftcardPrice: this.priceGiftCard,
        userTags: tags,
        userNotes: notes,
        isVip: isVip,
        isRecommended: isRecommended,
        isHabitual: isHabitual,
        isYate: isYate,
        birthday: birthday || null,
        balancePaid,
        peopleId,
        paymentType,
        amount,
        invoiceNumber,
        isRequest: 0
      };
      if (this.companionsData !== null) {
        dataReservation.companions = this.companionsData;
      }
      this.$store.dispatch({
        type: 'reservation:addReservationLink',
        data: dataReservation
      }).then(({
        data
      }) => {
        if (data.code === 200) {
          LocalStorage.remove('fileUploaded');
          LocalStorage.remove('sendReservation');
          this.reservation.loading = false;
          this.$buefy.notification.open({
            message: '<i class="icon_alert_success icon-mks green"></i>Se ha creado una precompra con éxito',
            type: 'is-success'
          });
          if (Object.keys(this.additionalQuestions).length > 0) {
            this.$store.dispatch({
              type: 'reservation:saveAnswersQuestions',
              data: {
                reservationId: data.data,
                answers: this.selectedAdditional
              }
            });
          }
          this.isOpen = false;
          var bodyEl = document.body;
          // eslint-disable-next-line
          classie.add(bodyEl, 'show-right-bar');
          if (this.fromTimeLine) {
            const bodyEl = document.body;
            // eslint-disable-next-line no-undef
            classie.add(bodyEl, 'cronology');
            // eslint-disable-next-line
            classie.remove(bodyEl, 'show-right-bar')
          }
        } else {
          this.reservation.loading = false;
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i>Ocurrió un error creando la reserva',
            type: 'is-danger'
          });
          this.error = data.message;
        }
      }).catch(fallo => {
        this.reservation.loading = false;
        const msg = JSON.parse(fallo.request.response).message;
        this.$buefy.notification.open({
          message: '<i class="icon_alert_danger icon-mks red"></i>' + msg,
          type: 'is-danger'
        });
        this.error = msg;
        // this.reservation = this.reservationClear;
      });
    },
    sendReservation () {
      LocalStorage.set('sendReservation', 1);
      let {
        amenities,
        comments,
        indicativo,
        bandera,
        phone,
        email,
        identityDocument,
        adjuntFile,
        hostId,
        people,
        adult,
        boy,
        hour,
        selectZone,
        displayName,
        experienceName,
        temperature,
        celebrationId,
        sendToClient,
        subscribed,
        typeDocument,
        birthday,
        peopleId,
        invoiceNumber
      } = this.reservation;
      const userId = this.user.id;
      this.reservation.loading = true;
      const vendorId = this.vendorId;
      selectZone = this.purgeZone(this.reservation.selectZone, this.reservation.selectTable);
      if (this.reservation.notificationSms) {
        this.reservation.notificationType = 'sms';
        this.reservation.typeNotification = 'sms';
      }
      if (this.reservation.notificationEmail) {
        this.reservation.notificationType = 'email';
        this.reservation.typeNotification = 'email';
      }
      if (this.reservation.notificationWhatsApp) {
        this.reservation.notificationType = 'whatsapp';
        this.reservation.typeNotification = 'whatsapp';
      }
      if (this.reservation.indicative) {
        indicativo = this.reservation.indicative;
      }
      this.reservation.isRequest = 0;
      if (this.reservation.isWarranty === 1) {
        if (this.reservation.priceTypeWarranty === 'person') {
          if (this.params.activeSelectorBoy) {
            const peopleFull = parseInt(people) - parseInt(adult);
            let totalFull = parseInt(boy) * this.reservation.priceWarrantyBoy;
            totalFull += parseInt(peopleFull) * this.reservation.priceWarranty;
            this.reservation.balancePaid = totalFull;
          } else {
            this.reservation.balancePaid = parseInt(people) * this.reservation.priceWarranty;
          }
        }
        if (this.reservation.priceTypeWarranty === 'fixed') {
          this.reservation.balancePaid = this.reservation.priceWarranty;
        }
      }
      const dataReservation = {
        ...this.reservation,
        typeDocument: typeDocument || null,
        displayName,
        amenities,
        phone: phone || null,
        indicativo,
        bandera,
        email: email || null,
        identityDocument: identityDocument || null,
        adjuntFile,
        sendToClient,
        celebrationId,
        comments,
        createdAt: hostId,
        people,
        adult,
        boy,
        date: hour,
        hour,
        seatingSectionId: selectZone,
        seatingTableId: this.reservation.selectTable,
        vendorId,
        userId,
        isConfirmed: this.isConfirmadas,
        temperature,
        experienceName,
        additionalQuestions: this.selectedAdditional,
        subscribed,
        referrer: this.reservation.referrer != null
          ? this.reservation.referrer
          : 'dashboard',
        giftcardId: this.giftcardId,
        giftcardPrice: this.priceGiftCard,
        userTags: this.reservation.tags,
        userNotes: this.reservation.notes ? this.reservation.notes : '',
        isVip: this.reservation.isVip,
        isHabitual: this.reservation.isHabitual,
        isRecommended: this.reservation.isRecommended,
        isYate: this.reservation.isYate,
        birthday: birthday || null,
        peopleId,
        invoiceNumber
      };
      if (this.companionsData !== null) {
        dataReservation.companions = this.companionsData;
      }
      if (this.infoReservation && this.editR) {
        dataReservation._id = this.infoReservation._id;
        dataReservation.reservationId = this.infoReservation.id;
        this.$store.dispatch({
          type: 'reservation:updateReservation',
          data: dataReservation
        }).then(({ data }) => {
          if (data.code === 200) {
            this.$buefy.notification.open({
              message: '<i class="icon_alert_success icon-mks green"></i> Se ha editado la reserva con éxito',
              type: 'is-success'
            });
            if (this.additionalQuestions.length > 0) {
              const helper = {};
              for (const property in this.additionalAnswrs) {
                helper[property] = { description: this.additionalAnswrs[property] };
              }
              this.$store.dispatch({
                type: 'reservation:saveAnswersQuestions',
                data: { reservationId: this.infoReservation.id, answers: helper }
              });
            }
            this.reservation.loading = false;
            this.isOpen = false;
            var bodyEl = document.body;
            // eslint-disable-next-line
            classie.add(bodyEl, 'show-right-bar')
            if (this.fromTimeLine) {
              const bodyEl = document.body;
              // eslint-disable-next-line no-undef
              classie.add(bodyEl, 'cronology');
              // eslint-disable-next-line
              classie.remove(bodyEl, 'show-right-bar')
            }
            this.close();
          } else {
            this.reservation.loading = false;
            this.$buefy.notification.open({
              message: '<i class="icon_alert_danger icon-mks red"></i> Error actualizando la reserva',
              type: 'is-danger'
            });
            LocalStorage.remove('fileUploaded');
            this.error = data.message;
          }
          this.$store.dispatch({
            type: 'reservation:reservations',
            data: { date: this.$moment(this.dateComplete).format('YYYY-MM-DD'), vendorId: this.vendorId }
          });
        }).catch(fallo => {
          this.$buefy.notification.open({
            message: '<i class="icon_alert_danger icon-mks red"></i> Error actualizando la reserva',
            type: 'is-danger'
          });
          LocalStorage.remove('fileUploaded');
          this.reservation.loading = false;
          this.error = fallo;
          this.isOpen = false;
        });
      } else {
        this.$store
          .dispatch({
            type: 'reservation:addReservation',
            data: dataReservation
          })
          .then(({ data }) => {
            if (data.code === 200) {
              LocalStorage.remove('fileUploaded');
              LocalStorage.remove('sendReservation');
              this.reservation.loading = false;
              this.$buefy.notification.open({
                message:
                  '<i class="icon_alert_success icon-mks green"></i>Se ha creado una reserva con éxito',
                type: 'is-success'
              });
              if (Object.keys(this.additionalQuestions).length > 0) {
                const helper = {};
                for (const property in this.additionalAnswrs) {
                  helper[property] = { description: this.additionalAnswrs[property] };
                }
                this.$store.dispatch({
                  type: 'reservation:saveAnswersQuestions',
                  data: {
                    reservationId: data.data,
                    answers: helper
                  }
                });
              }
              this.isOpen = false;
              const bodyEl = document.body;
              // eslint-disable-next-line
              classie.add(bodyEl, "show-right-bar");
              if (this.fromTimeLine) {
                const bodyEl = document.body;
                // eslint-disable-next-line no-undef
                classie.add(bodyEl, 'cronology');
                // eslint-disable-next-line
                classie.remove(bodyEl, 'show-right-bar')
              }
            } else {
              this.reservation.loading = false;
              this.$buefy.notification.open({
                message:
                  '<i class="icon_alert_danger icon-mks red"></i>Ocurrió un error creando la reserva',
                type: 'is-danger'
              });
              this.error = data.message;
            }
          })
          .catch((fallo) => {
            this.error = JSON.parse(fallo.request.response).message;
            this.reservation.loading = false;
            // this.reservation = this.reservationClear;
          });
      }
    },
    async getDataHost () {
      try {
        this.reservation.loading = true;
        const data = await this.$store
          .dispatch({
            type: 'reservation:searchHost',
            data: {
              vendorId: this.vendorId,
              userId: this.user.id
            }
          });
        const aux = [];
        const hostes = data.data;
        hostes.map(function (data) {
          aux.push({
            value: data.id,
            label: data.nombres,
            pin: data.pin
          });
        });
        if (aux.length > 0) {
          this.hostList = [];
          this.hostList = aux;
        } else {
          this.hostList = [];
          this.hostList = aux;
        }
        this.reservation.loading = false;
      } catch (fallo) {
        const aux = [];
        this.hostList = [];
        this.hostList = aux;
        this.error = fallo;
        this.reservation.loading = false;
      }
    },
    getTypeReservationList () {
      if (this.params.listReservationType) {
        this.$store
          .dispatch({
            type: 'whiteLabel:getReservationTypes',
            data: {
              vendorId: this.vendorId
            }
          })
          .then((data) => {
            const aux = [];
            const types = data;
            types.map(function (data) {
              aux.push({
                value: data.id,
                label: data.type
              });
            });
            if (aux.length > 0) {
              this.typeReservationList = [];
              this.typeReservationList = aux;
            } else {
              this.typeReservationList = [];
              this.typeReservationList = aux;
            }
          })
          .catch((fallo) => {
            const aux = [];
            this.typeReservationList = [];
            this.typeReservationList = aux;
            this.error = fallo;
            this.reservation.loading = false;
          });
      }
    },
    purgeZone (selectZone, selectTable) {
      let purgedZones = [];
      if (selectZone.length > 1) {
        _.forEach(selectTable, function (value) {
          purgedZones = _.concat(purgedZones, value.idSection);
        });
        purgedZones = _.uniq(purgedZones);
      } else {
        purgedZones = selectZone;
      }
      return purgedZones;
    },
    newOriginPeople (q) {
      this.newOriginP = q;
    },
    saveOriginPeople (person) {
      const dataNewPeople = {
        vendorId: this.vendorId,
        newOriginPeople: person
      };
      this.$store
        .dispatch({
          type: 'reservation:addOriginPeople',
          data: dataNewPeople
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.$store
              .dispatch({
                type: 'reservation:getOriginVendorPeople',
                data: {
                  vendorId: this.vendorId
                }
              })
              .catch((fallo) => {
                this.error = fallo;
                this.reservationDashboard.originP = null;
                this.reservation.loading = false;
              });
          } else {
            this.reservation.loading = false;
            this.error = data.error;
          }
        })
        .catch((fallo) => {
          this.error = fallo;
          this.reservation.loading = false;
        });
    },
    newOriginDetail (q) {
      this.newOriginD = q;
    },
    saveOriginDetail (typeDetail) {
      const dataNewDetail = {
        vendorId: this.vendorId,
        newOriginDetail: typeDetail.companyName,
        typeDetail: typeDetail.companyType === 'Hoteles' ? 2 : 3
      };
      this.$store
        .dispatch({
          type: 'reservation:addOriginDetail',
          data: dataNewDetail
        })
        .then(({ data }) => {
          if (data.code === 200) {
            this.$store
              .dispatch({
                type: 'reservation:getOriginVendor',
                data: {
                  vendorId: this.vendorId
                }
              })
              .catch((fallo) => {
                this.reservation.originDetails = null;
                this.error = fallo;
                this.reservation.loading = false;
              });
          } else {
            this.reservation.loading = false;
            this.error = data.error;
          }
        })
        .catch((fallo) => {
          this.error = fallo;
          this.reservation.loading = false;
        });
    },
    close () {
      const bodyEl = document.body;
      if (this.toList) {
        this.$emit('close-modal-itemList', false);

        // eslint-disable-next-line
        classie.remove(bodyEl, 'show-right-bar')
        // eslint-disable-next-line
        classie.add(bodyEl, 'show-right-bar')
      } else {
        LocalStorage.remove('fileUploaded');
        LocalStorage.remove('sendReservation');
        this.isOpen = false;
        this.setCloseEditReservation();
        // eslint-disable-next-line
        classie.add(bodyEl, "show-right-bar");
        this.$store.commit(reservationTypes.mutations.setCompanionsData, null);
      }
      if (this.fromTimeLine) {
        const bodyEl = document.body;
        // eslint-disable-next-line no-undef
        classie.add(bodyEl, 'cronology');
        // eslint-disable-next-line
        classie.remove(bodyEl, 'show-right-bar')
      }
    },
    updateAdjuntFile ({ adjuntFile, sendToClient }) {
      this.reservation.adjuntFile = adjuntFile;
      this.reservation.sendToClient = sendToClient;
    },
    setAdultsBoys (type, value) {
      if (type === 'people') {
        this.reservation.adult = value;
      } else {
        this.reservation.boy = value;
      }
    },
    setPeopleUpdate (e) {
      this.reservation.people = e;
    }
  },
  watch: {
    formInformation: {
      handler: function (value) {
        this.reservation = { ...this.reservation, ...value };
        if (!this.reservation.email) {
          this.reservation.errorMail = false;
          return;
        }
        // Si requieren actualizar el regex debe actualzarlo tambien en Input
        this.reservation.errorMail = false;
        // const regex = /^[a-zA-Z0-9_%+-]+(\.[a-zA-Z0-9_%+-]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)?\.[a-zA-Z]{2,}$/i;
        const regex = /^[a-zA-Z0-9_%+-]+(\.[a-zA-Z0-9_%+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i;
        this.reservation.errorMail = !regex.test(this.reservation.email);
      },
      deep: true
    },
    'reservation.adult': {
      handler () {
        if (this.params.activeSelectorBoy === 1) {
          this.reservation.people = this.reservation.adult + this.reservation.boy;
        }
      }
    },
    'reservation.boy': {
      handler () {
        if (this.params.activeSelectorBoy === 1) {
          this.reservation.people = this.reservation.adult + this.reservation.boy;
        }
      }
    }
  }
};
</script>
<style lang="scss">
@use "../../assets/styles/addReservation/reservation.scss";
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700);
#Reservations .alert-bigbox.contentExistent {
  @media screen and (max-width: 600px) {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
  }
}
.closeButton {
  font-size: 35px;
  transform: rotate(45deg);
  max-height: 15px;
  line-height: 10px;
}
.hideSubzone {
  visibility: hidden;
  height: 0px;
}
.errorShow {
  max-height: 3em;
  overflow: hidden;
  max-width: 80%;
}
.title-bar.reservaBar {
  @media screen and (max-width: 600px) {
    border-bottom: 4px solid #00adc6;
  }
}
.title-bar.reservaBarReq {
  @media screen and (max-width: 600px) {
    border-bottom: 4px solid #8eb2bd;
  }
}
.title-bar.precompraBar {
  @media screen and (max-width: 600px) {
    border-bottom: 4px solid #bdc30e;
  }
}
.articialSpace {
  display: block;
  height: 0.7rem;
  width: 100%;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
#Reservations .alert-bigbox.manualmente {
  display: grid;
  min-height: fit-content;
  max-height: unset;
  grid-template-columns: 60px 3fr 36px;
  gap: 3px;
  padding: 5px 4px;
  max-width: 405px;
  @media screen and (min-width: 600px) {
    width: 80%;
    margin-top: unset;
  }
  .icon_danger {
    max-height: 30px;
    min-height: 30px;
  }
  .btn-dg {
    justify-self: center;
    color: #444b57;
    position: relative;
    top: 2px;
    em {
      font-size: 20px;
    }
  }
  span {
    min-width: 251px;
    width: 100%;
    max-height: unset;
    line-height: 1em;
    font-size: 12px;
    text-align: left;
    max-width: unset;
    min-height: 26px;
    margin: unset;
    height: fit-content;
    @media screen and (min-width: 600px) {
      line-height: 1.3em;
    }
  }
}
.toList {
  .notificationContainer {
    margin-top: 10px;
  }
}
#Reservations .reservations-content-box .Contenedor .rigth-later-flex {
  @media screen and (max-width: 600px) {
    flex-direction: column;
    .mr-15p {
      margin-left: unset;
    }
  }
}
#Reservations .consumeValue, .invoiceNumber {
  margin-top: 10px;
  border: 1px solid rgba(68, 75, 87, 0.5);
  border-radius: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: 50%;
  @media screen and (max-width: 460px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (min-width: 600px) and (max-width: 900px) {
    width: 50%;
  }
  @media screen and (min-width: 1000px) {
    margin-bottom: 10px;
  }
  div {
    border-right: solid rgba(68, 75, 87, 0.5) 1px;
    border-radius: 1px;
    height: 80%;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      color: #606773;
    }
  }
  input {
    flex: 1;
    border: unset;
    outline: unset;
    height: 100%;
    background-color: transparent;
    text-indent: 6px;
  }
  .consumeParent {
    display: flex;
    .ml-10 {
      margin-left: 10px;
    }
    @media screen and (max-width:460px) {
      flex-direction: column;
    }
  }
  #Reservations .invoiceNumber {
    @media screen and (max-width:460px) {
      margin-left: unset;
    }
  }
}
</style>
